#resumeWrapper{
    margin: 0 10%;
    padding: 0 5% 4% 5%;
    border-style: solid;
    border-width: 1px;
    font-family: Calibri, "Times New Roman", Times, serif;
    background-image: url("../../../public/white_grey_gradient_background_1-1fd.jpg");
    background-attachment: fixed;
}

#resumeHeader{
    text-align: center;
    font-family: 'Times New Roman', Times, serif;
}

#myName{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.5em;
    font-weight: bold;
}

#summary{
    color: #404040;
    font-weight: bold;
    font-size: 1.1em;
}

#navbar{
    background-color: #a6a6a6;
    padding: 5px 0 5px 10px;
    border-radius: 10px;
    box-shadow: 3px 5px 8px;
}

.navItem{
    display: inline-block;
    padding: 5px;
    margin-right: 10px;
    cursor: pointer;
    color: mintcream; /*floralwhite;*/  /*lightcyan;*/
    font-size: 1.25em;
}

.navSelected{
    background-color: lightcyan;
    border-radius: 10px;
    box-shadow: 3px 3px 8px;
    font-weight: bold;
    color: black;
}

.horizontalList{
    display: flex;
    column-gap: 60px;
}

.empSubHeading{
    display: inline-block;
    margin-bottom: 0.5em;
}

.empDates{
    float:right;
}

.jobWrapper{
    margin-bottom: 1em;
}

.sectionHeading{
    color: #31849B;
    margin-bottom: 0.5em;
}

.empList{
    margin-top: 0.5em;
}

#skillsWrapper li{
    padding-bottom: 4px;
}