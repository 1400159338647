body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#baseballWrapper{
  display: grid;
  grid-template-columns: 200px max-content max-content;
}

:root{
  --tableCellRightPadding: 7px;
}

.baseballTable{
  border-collapse: collapse;
  display: inline-block;
}

.baseballTable thead{
  position: sticky;
  top: 0px;
  background-color: white;
}

.baseballTable:not(.sideTable) th:not(.noSort){
  padding-right: var(--tableCellRightPadding);
  cursor: pointer;
}

.playerRow{
  text-align: center;
}

tr.playerRow td{
  padding: 0px var(--tableCellRightPadding) 0px 0px;
}

tr.playerRow td a:link{			/*https://www.w3schools.com/css/css_link.asp*/
	color:black;
    text-decoration: none;
}

tr.playerRow td a:visited{
	color:purple;
}

tr.playerRow td a:hover{
	color:blue;
}

tr.playerRow:nth-of-type(odd){
	background-color: #eee;
}

tr.playerRow:nth-of-type(even){
	background-color: white;
}

tr.playerRow:hover{
  background-color: #FCFBD0;
}

tr.playerRow td.sideLinkCell{
  cursor: pointer;
  padding-right: 0px;
}

.selectedSideLink{
  background-color: lightskyblue;
}

#batters tr td:nth-of-type(13){
	border-left:1px solid;
}

#pitchers tr td:nth-of-type(9){
	border-left:1px solid;
}

.sortedHeading{
  font-style: italic;
  color: darkmagenta;
}

.leftContent{
  /* display: inline-block; */
  /* vertical-align: top; */
  /* padding-right: 7px; */
  background-color: grey;
  /* padding-top: 1em; */
  min-height: 100vh;
}

#teamWrapper{
  margin:auto;
  width: fit-content;
}

#teamSelect{
  background-color: aliceblue;
  border-radius: 7px;
}

#leftLinkWrapper{
  position: sticky;
  top: 1em;
}

.leftLink{
  width: fit-content;
  padding:10px;
  margin: auto auto 15px auto;
  background-color: aliceblue;
  border-radius: 12px;
  box-shadow: 3px 3px 8px;
  cursor: pointer;
}

.selectedLink{
  background-color: aliceblue;
  border-radius: 12px;
  box-shadow: inset 2px 1px 6px;
  cursor: default;
}

.customDatePickerWidth{
  width: 150px;
  margin: auto;
}

.customDatePickerWidth div.flatpickr-calendar,
.customDatePickerWidth div.flatpickr-days,
.customDatePickerWidth div.flatpickr-rContainer{
  width: 100%;
}

.customDatePickerWidth div.dayContainer{
  min-width: auto;
}

.customDatePickerWidth div.flatpickr-weekdays{
  display: none;
}

.customDatePickerWidth div.flatpickr-months{
  height: 60px;
  flex-direction: column;
}

.customDatePickerWidth select.flatpickr-monthDropdown-months{
  text-align: center;
}

.flatpickr-day.selected{
  background-color: aliceblue !important;
  color: cadetblue !important;
  border-color: cadetblue !important;
}

#dateWrapper > input{
  display: none;
}

.errText{
  margin: 3rem 0 0 3rem;
  color: red;
}

.sideTable{
  margin-left: 60px;
  border-style: groove;
  border-width: thin;
  height: fit-content;
  position: sticky;
  top: 0px;
  max-height: 100vh;
  overflow: auto;
}

